import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from '@reach/router';

import paths from 'constants/paths';
import { axiosGet } from 'utils/api-utils';
import { authDomain } from 'utils/envConfig';
import { postOrderCleanup } from './CheckoutHelpers';
import logError from 'utils/errorHandler';
import { getUrlDiscount } from 'reduxState/cart';
import { getLoginState, getCAPIUserInfo, getCustomer } from 'reduxState/user';

/* /affirm-success - affirm success! Perform post order cleanup for Affirm and then redirect to order conf */
const AffirmHandlingRoutes = ({ orderId }) => {
  const dispatch = useDispatch();
  const urlDiscount = useSelector(getUrlDiscount);
  const isLoggedIn = useSelector(getLoginState);
  const capiData = useSelector(getCAPIUserInfo);
  const customer = useSelector(getCustomer);
  useEffect(() => {
    const postAffirmCleanup = async () => {
      try {
        const url = `/app/public/getOrder/${orderId}`;
        // Get order information
        const { data } = await axiosGet(url, { headers: { authDomain } });
        // Passing urlDiscount as an object for backwards compatibility. Other usages currently pass cart
        postOrderCleanup({ order: { ...data, eventPayMethod: 'AFFIRM' }, cart: { urlDiscount }, dispatch, isLoggedIn, capiData, customer });
      } catch (error) {
        logError(error);
      }
    };
    postAffirmCleanup();
  }, []);

  return <Redirect noThrow to={`${paths.ORDER_CONFIRMATION}/${orderId}`} />;
};

AffirmHandlingRoutes.propTypes = {
  orderId: PropTypes.string,
};
export default AffirmHandlingRoutes;
